import React from 'react';
import cx from 'classnames';
import s from './ProductPageApp.scss';

import ProductPageBody from '../Components/ProductPageBody/ProductPageBody';

function ProductPageApp() {
  const classNames = cx(s.main, s.viewer_classic);

  return (
    <div className={s.container}>
      <main className={classNames}>
        <ProductPageBody />
      </main>
    </div>
  );
}

export default ProductPageApp;
