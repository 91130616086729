import React from 'react';
import ProductPageApp from './ProductPageApp/ProductPageApp';
import { ControllerContext } from './ControllerContext';
import { StateProvider } from './stores';
import { IControllerProps } from '../../../types/app.types';

export default function ProductPageAppRoot(controllerProps: IControllerProps) {
  return (
    <StateProvider>
      <ControllerContext value={controllerProps}>
        <ProductPageApp />
      </ControllerContext>
    </StateProvider>
  );
}
