import React from 'react';
import { IControllerProps } from '../../../types/app.types';

const Context = React.createContext({} as IControllerProps);

export const useControllerProps = () => React.useContext(Context);

export const ControllerContext = ({
  value,
  children,
}: {
  value: IControllerProps;
  children: React.ReactNode;
}) => {
  return <Context.Provider value={value}>{children}</Context.Provider>;
};
