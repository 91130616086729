import React from 'react';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { Button, PRIORITY } from 'wix-ui-tpa/Button';
import { classes } from './BuyButton.st.css';
import settingsParams from '../../../settingsParams';
import { useControllerProps } from '../../ControllerContext';
import { GiftCardProduct } from '../../../../../../../../types';
import { useState } from '../../stores';

const BuyButton = () => {
  const {
    state: { checkout: checkoutState },
  } = useState();
  const settings = useSettings();
  const { isPreview } = useEnvironment();
  const { product, currencyCode } = useControllerProps().giftCardStore;
  const { buyGiftCard, creatingCheckout, redirect_path, checkout } =
    useControllerProps().checkoutStore;

  if (checkout && redirect_path && window) {
    const currentDirectory = window.location.pathname
      .split('/')
      .slice(0, -1)
      .join('/');
    window.location.href = `${currentDirectory}${redirect_path}`;
  }

  return (
    <Button
      data-hook="rise-gift-card-buy-button"
      className={classes.button}
      fullWidth
      upgrade
      disabled={isPreview || creatingCheckout}
      priority={PRIORITY.primary}
      onClick={() =>
        buyGiftCard(
          product as GiftCardProduct,
          currencyCode,
          checkoutState.purchaseOptions,
        )
      }
    >
      {settings.get(settingsParams.buyButtonText)}
    </Button>
  );
};

export default BuyButton;
